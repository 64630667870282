  import { Splide } from "@splidejs/splide";

  // Swatch Slider
  class SwatchSlider extends HTMLElement {
    constructor() {
      super();

    }
    connectedCallback() {
      var main = new Splide(this, {
         pagination: false,
         fixedWidth: '24px',
         fixedHeight: '24px',
         width      : '100%',
         perMove: 1,
         arrows    : true,
         padding: { right: '20%' },
         arrowPath  : 'M14.0959 7L27 19.5L14.0959 32L12 29.9697L22.8082 19.5L12 9.03026L14.0959 7Z',
         classes: {
          // Add classes for arrows.
          arrows: 'splide__arrows',
          arrow : 'splide__arrow product-card__arrow',
          prev  : 'splide__arrow--prev hidden',
          next  : 'splide__arrow--next bg-transparent',
         },
         breakpoints : {
          1024: {
            fixedWidth: '16px',
            fixedHeight: '16px',
          }
      }
       });
     main.mount();
  }
  }

  customElements.define('swatch-slider', SwatchSlider);
